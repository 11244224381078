.MuiTabs-flexContainer button {
    min-width: auto;
}

.MuiTabs-flexContainer .Mui-selected {
    background: rgba(187, 201, 242, 0.15);
    color: #fff !important;
    border-radius: 2px;
    background: #373C3E;
    border-radius: 6px;
}

.MuiTabs-flexContainer button {
    color: rgba(255, 255, 255, 0.35);
    font-size: 11px;
    padding: 4px 8px;
    min-height: auto;
    margin: 2px;
}

span.MuiTabs-indicator {
    display: none;
}

.MuiTabs-flexContainer {
    padding: 0 !important;
    /* border: 1px solid #000 !important; */
    margin: 3px 10px 3px 10px;
    border-radius: 2px;
    text-transform: none;
}

.market-left-left {
    display: flex;
    flex-direction: row !important;
}

.market-left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.market-left-left label {
    color: #fff;
    font-size: 12px;
}

.market-left span {
    color: rgba(255, 255, 255, 0.58);
    font-size: 12px;
}

.market-right-inner {
    display: flex;
    flex-direction: column;
}

.market-right-inner label {
    color: #f00;
}

.market-right-inner span {
    color: rgba(255, 255, 255, 0.58);
}

.market-left-left button {
    display: flex;
    margin-left: 5px;
    padding: 1px 4px;
    line-height: 10px;
    align-items: center;
    color: #717672;
    justify-content: center;
    font-size: 12px;
    background: rgba(187, 201, 242, 0.15);
    /* box-shadow: 0 0 0 1px rgb(0 0 0 / 80%); */
    border-radius: 2px;
    background: #373C3E;
    border-radius: 6px;
    border: none;
    padding: 5px !important;
    font-weight: 700;
}

.market-right-inner label, .market-right-inner span {
    font-size: 12px;
}

.fontsize11 {
    font-size: 12px !important;
}

.searchleft {
    margin-right: 5px;
}

.search-left-top {
    width: 33px;
    height: 33px;
    align-items: center;
    display: flex;
    justify-content: center;
    background: #373C3E;
    border-radius: 9px;
    margin-left: auto;
}

.search-left-top svg {
    font-size: 18px;
}

.carouselgrid .MuiBox-root {
    border: none !important;
}
/* .err{
    color: #f00;
}
.ok{
    color: forestgreen;
} */

.buttons-block button {
    background: #373C3E;
    border-radius: 6px;
    color: #fff;
    border: none;
    padding: 0px !important;
    /* height: 28px; */
    font-size: 12px;
    line-height: 18px;
    flex-grow: 1;
    width: 100px;
    flex-basis: auto;
    flex-shrink: 0;
}


.buttons-block button:hover {
    border: none !important;
}

.left-table-body-content tr.active{
    background: #cccccc38 !important;
}
.left-table-body-content{margin-top: 20px;}

.buttons-block button {
    /* white-space: nowrap; */
    text-transform: none;
}

button.activeTabCls {
    background: #676767 !important;
}
