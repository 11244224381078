.Launchpad {
    color: rgba(255, 255, 255, 1);
}

.launchpad-content {
    max-width: 900px;
    margin: 0 auto;
}

.launchpad-block1 {
    background: rgba(41, 42, 43, 1);
    border-radius: 9px;
    /* gap: 15px; */
    padding: 25px 0;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.block1-purchase-price {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column !important;
    align-items: center;
}

.launchpad-block1 h6,
.launchpad-price-pool h6 {
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    color: rgba(120, 126, 135, 1);
    margin: 0;
}

.launchpad-block1 span {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
}

.price-pool-block {
    margin-bottom: 8px !important;
}

.launchpad-price-pool {
    background: rgba(41, 42, 43, 1) !important;
    padding: 12px 30px 15px 28px;
    border-radius: 9px !important;
    min-height: 207px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.card-content {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.pricepool-btn {
    background-color: rgba(255, 255, 255, 1) !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 15px !important;
    letter-spacing: -0.03em !important;
    color: rgba(40, 51, 64, 1) !important;
    padding: 10px 15px !important;
    border-radius: 9px !important;
    margin-top: 9px !important;

}
.project-name p {
    font-size: 18px;
    font-weight: 700;
    color: #787E87;
}
.project-name span {
    font-size: 16px;
    color: #fff;
}
.launchpad-page {
    background: #000;
    height: 100vh;
}
.crypto-banner-txt.applctn-banner-txt.Voting-block.back-btn {
    display: flex;
    gap: 15px;
    align-items: normal;
}

.back img {
    width: 40px;
}
.create-page{
    height: auto !important;
}

.launchpad-price-pool h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    font-family: Space Grotesk;
    color: rgba(255, 255, 255, 1);
    margin: 0;
}

.launchpad-price-pool h4 {
    font-family: Space Grotesk;
    font-size: 15px;
    font-weight: 700;
    /* line-height: 0; */
    text-align: left;
}

.launchpad-price-pool p {
    font-family: Space Grotesk;
    font-size: 14px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(120, 126, 135, 1);
    margin: 0;
}

.buy-btn {
    text-align: left;
}

.launchpad-page .MuiPaper-root {
    background: #000;
    border-radius: 0;
    margin-top: 0;
}

.price-pool {
    margin-top: 30px;
    height: 70vh;
}

.linear-bar .MuiLinearProgress-root {
    height: 19px;
    border-radius: 25px;
    background: rgb(41 42 43);
}

.linear-bar .MuiLinearProgress-bar1Determinate {
    background: #53d6ff;
}

.spacek-logo {
    text-align: left;
}

.spacek-logo img {
    width: 50px;
}

.spacek-head h5 {
    text-align: left;
    font-size: 15px;
    margin: 0;
    font-weight: 700;
}

.spacek-party {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.smart-contract-bar {
    text-align: left;
}

.spacek-head p {
    margin: 0;
    text-align: left;
    color: #787E87;
    font-weight: 700;
    line-height: 1.1;
    max-width: 465px;
}

.spacex-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.grt-skk {
    white-space: nowrap;
}

.grt-skk button {
    background: #53D6FF !important;
    color: #000;
    padding: 4px 23px 4px 22px;
}

.smart-contract-bar {
    text-align: left;
    display: flex;
}

.smart-contract-bar a {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 6px;
    background: #373C3E;
    border-radius: 10px;
    padding: 6px;
    cursor: pointer;
}

.website-contract {
    display: flex;
    gap: 10px;
    padding: 20px 0;
}

.warm-up p {
    margin: 0;
}

.warm-up p:first-child {
    color: #787E87;
    font-weight: 700;
}

.loader-time {
    display: flex;
    justify-content: space-between;
}

.linear-bar .MuiSlider-rail {
    height: 10px;
    background: rgb(41 42 43);
}

.linear-bar .MuiSlider-track {
    height: 10px;
    background: #53d6ff !important;
}

.linear-bar .MuiSlider-root {
    color: #53d6ff;
}


/* sankar */

.launchpadtab .MuiTabs-flexContainer button {
    font-size: 15px !important;
}

.chip-span span,
.create-disclaimer span {
    color: #fff;
    font-size: 12px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-weight: 800;

}


.chip-span {
    margin: 15px 0px;
    flex-flow: wrap;
    gap: 10px;
}

.chip-span div {
    height: 25px;
}

.create-input fieldset {
    height: auto !important;
    border-radius: 8px !important;
}

.create-input {
    margin: 20px 10px;
    color: #fff;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    /* justify-content: center; */
}


.create-input span {
    font-weight: 800;
    font-size: 15px;
    display: flex;
    gap: 5px;
    align-items: center;
    white-space: nowrap;
}
.admin-fee-direct {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.admin-fee-direct .swaping-rate-block-chain {
    color: #fff;
}

.create-input input {
    color: #fff;
    /* padding: 10px; */
}

.create-input label,.create-input label.Mui-focused{
    color: #fff !important;
    font-size: 15px;
}

.create-input .MuiFormControl-root {
    width: 270px;
}

.create-input .MuiStack-root {
    width: 270px;
}

.create-input .MuiStack-root .MuiFormControl-root {
    width: 100%;
}

.create-input svg {
    fill: #fff;
}

.form-main {
    padding: 10px;
    border-radius: 12px;
    box-shadow: rgb(255 241 241 / 15%) 0px 50px 100px -20px, rgb(0 0 0 / 0%) 0px 30px 60px -30px, rgb(255 255 255) 0px -2px 6px 0px inset;
    /* justify-content: center; */
    margin: 15px 10px;
    height: 210px;
    overflow-y: scroll;
}

.form-main2{
    padding: 30px;
    border-radius: 12px;
    box-shadow: rgb(255 241 241 / 15%) 0px 50px 100px -20px, rgb(0 0 0 / 0%) 0px 30px 60px -30px, rgb(255 255 255) 0px -2px 6px 0px inset;
    /* justify-content: center; */
    margin: 15px 10px;
}

.grt-skk.launch {
    text-align: center;
    margin: 15px 0px;
}

.backdrop {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    background: #312d2d;
    padding: 15px;
    border-radius: 15px;
    width: fit-content;
    margin: auto;
    text-align: center;
}

.backdrop img {
    width: 250px;
    height: 200px;
}

.thankyou {
    padding: 5px;
    border-radius: 12px;
    background-color: green;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-flow: wrap;
    justify-content: center;
}

.result-launch {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    font-weight: 800;
    font-size: 16px;
    justify-content: space-between;
    margin: 5px 15px;
}


.result-launch span {
    font-weight: 400 !important;
    word-break: break-all;
}




.closeicon svg {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 25px;
    cursor: pointer;
}

.create-disclaimer {
    text-align: center;
}

.form-main{
    text-align: center;
}

.link-div{
    word-break: break-all;
    /* text-align: start; */
}

.form-main a{
    color: #FFF;
    text-decoration: none;
    cursor: pointer;

}


.form-main a:hover{
    color: blue;
}

.nodatafound{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
}

/* @media (min-width: 1px) and (max-width: 575.98px) {
    .launchpad-block1 {
        gap: 15px;
    }
    .price-pool-block {
        flex-wrap: wrap !important;
    }
 }
@media (min-width: 576px) and (max-width: 767.98px) {
    .launchpad-content {
        max-width: 100% !important;
    }

    .launchpad-block1 {
        gap: 10px;
    }



}
@media (min-width: 768px) and (max-width: 991.98px) {
    .launchpad-content {
        max-width: 100% !important;
    }

    .launchpad-block1 {
        gap: 10px;
    }
 
}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .block1-market-cap {
        padding-top: 20px;}
} */

@media(max-width: 767.98px) {
    .website-contract {
        flex-wrap: wrap;
    }
    .price-pool{
        height: auto !important;
    }
    .form-main2{
        width: auto !important;
    }
    .create-input span{
        flex-direction: column;
    }
    .create-total svg {
        transform: rotate(92deg);
    }
    
    .spacex-part {
        display: block;
    }

    .launchpad-block1 {
        gap: 15px;
    }

    .launchpad-block1 span {
        font-size: 14px;
    }

    .backdrop {
        width: 80%;
    }
    .launchpadtab {
        flex-wrap: wrap;
    }
    .buy-swap{
        height: 80vh;
        overflow-y: scroll;
    }
    .stylebox{
        width: 82% !important;
    }
    .create-total{
        flex-direction: column;
        align-items: center !important;
    }
    .create-list{
        width: 82% !important;
    }
    .list-detail{
        flex-direction: column !important;
        align-items: baseline !important;
    }
    .form-main2{
        padding: 15px;  
    }
    .project-name p{
        font-size: 16px;
    }
    .project-detail-full{
        flex-direction: column !important;
        gap: 0 !important;
    }
    .sales-head {
        position: sticky !important;
        width: auto !important;
        top: -21px !important;
    }
    .blockchains button.MuiSelect-root {
        min-width: 250px !important;
        margin-bottom: 25px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .launchpad-block1 {
        gap: 10px;
    }
    .header-part.footer-part ul.header-menus li a {
        font-size: 15px !important;
    }
    .price-pool{
        height: auto !important;
    }
    .buy-swap{
        height: 80vh;
        overflow-y: scroll;
    }
    .form-main2{
        width: auto !important;
    }
    .create-list{
        width: 90% !important; 
    }
    button.Swap-Coins {
        font-size: 15px !important;
        font-weight: 600 !important;
    }
 .swap-token.token-input input{
        position: relative !important;
        z-index: -1 !important;
    }
}

@media(max-width: 575.98px) {
    .create-input {
        /* flex-direction: column; */
        align-items: flex-start;
        justify-content: center;
    }

    .create-input .MuiFormControl-root,
    .create-input .MuiStack-root {
        /* width: 100%; */
    }
}
.create-total {
    display: flex;
    align-items: center;
    gap: 10px;
}
.MuiTabs-flexContainer button{
    padding: 5px 15px !important;
}
.price-pool th, .price-pool td {
    color: #787E87;
}
.stylebox  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: #2c2a2a;
    padding: 20px;
    border-radius: 15px
   
  }
.styletoken  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: #2c2a2a;
    padding: 20px;
    border-radius: 15px;
   
  }
  .create-list{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 950px;
    background: #2c2a2a;
    padding: 20px;
    border-radius: 15px;
    color: #fff;
  }
  .buy-btn {
    display: flex;
    gap: 15px;
}
.list-detail {
    display: flex;
    align-items: baseline;
    gap: 5px;
    flex-direction: column;
}
.create-history {
    overflow-y: scroll;
    height: 67vh;
    padding: 16px 0;
}
.create-history::-webkit-scrollbar, .buy-swap::-webkit-scrollbar{
    width: 3px !important;
    height: 0 !important;
}
.create-history::-webkit-scrollbar-thumb, .buy-swap::-webkit-scrollbar-thumb{
    background: #787E87 !important;
}
.project-name img {
    width: 98px;
    height: 39px;
}
.token-button-link {
    background: #373C3E;
    border-radius: 32px;
    margin-top: 20px;
    color: #1976d2;
    padding: 5px 8px;
    text-align: center;
    font-size: 15px;
}
button.Swap-Coins:hover{
    color: #fff !important;
}
.launch-swap{
    flex-direction: column;
    gap: 10px;
}
.sales-head {
    position: fixed;
    top: 0;
    background: #2c2a2a;
    width: 95%;
}
.project-detail-full {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 35px;
    background: #2c2a2a;
    padding: 15px 0;
}
.project-detail-full .list-detail {
    justify-content: center;
    flex-direction: row !important; 
    gap: 25px;
    align-items: center !important;
}
.pop-block {
    margin-top: 35px !important; 
}