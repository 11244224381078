.liquid-staking-first {
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    overflow: inherit;
    background: #fff;
    border-radius: 24px 24px 0px 0;
}

.liquid-staking .heading-title p {
    color: #7a6eaa;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    font-size: 14px;
}

.liquid-staking .heading-title h4 {
    color: #280d5f;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 10px !important;
}

.liquid-staking h4,
.liquid-staking p {
    margin: 0;
}

.heading-title {
    padding: 24px;
    border-bottom: 1px solid #e7e3eb;
}

.liquid-stake {
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    overflow: inherit;
    background: #fff;
    border-radius: 24px 24px 0px 0;
}

.liquid-stake {
    border-radius: 0px 0 24px 24px;
}

.liquid-stake .heading-title {
    border: none;
}

.liquid-stake .heading-title h6 {
    color: #7645d9;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 12px;
    text-align: start;
    margin-top: 0;
}

.xchange-rate h4 {
    color: #7a6eaa;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px !important;
}

.xchange-rate p {
    color: #280d5f;
    font-weight: 400 !important;
    line-height: 1.5;
    font-size: 14px !important;
}

.xchange-rate {
    display: flex;
    justify-content: space-between;
}

.proceed-btn button {
    width: 90%;
    border: 0px;
    border-radius: 16px;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 48px;
    padding: 0px 24px;
    background-color: #1fc7d4;
    color: #fff;
}

.proceed-btn button:hover {
    background-color: #1fc7d4;
}

.proceed-btn {
    padding: 20px 0;
}

.liquid-staking .sortby label {
    color: #000 !important;
    position: absolute;
    left: 0;
    top: -9px;
}

.liquid-staking .sortby {
    margin: 15px 0;
}

.liquid-staking .sortby svg {
    color: #fff;
}

.liquid-stake .sortby fieldset {
    height: 50px;
}

.liquid-stake .sortby {
    margin: 30px 0;
}

div#demo-simple-select {
    padding-left: 25px;
    /* padding-top: 12px; */
    font-weight: 700;
    color: #fff;
}

.token-deposit-amount {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.token-deposit-amount-left label {
    font-size: 16px;
    font-weight: 700;
}

.token-deposit-amount-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.token-deposit-amount-right {
    color: #000;
}

.token-deposit-amount-right label {
    margin-right: 5px;
}

.token-deposit-amount {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.token-deposit-amount-left label {
    font-size: 16px;
    font-weight: 700;
}

.token-deposit-amount-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.token-deposit-amount-right {
    color: #000;
}

.token-deposit-amount-right label {
    margin-right: 5px;
}

.deposit-amount fieldset {
    /* width: 100%; */
    height: 50px;
    text-align: right;
    border: none !important;
}

.deposit-amount .MuiFormControl-root.MuiTextField-root {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 10px;
}

div#arrowshow {
    position: relative;
}

div#arrowshow svg {
    position: absolute;
    left: 19px;
    top: 33px;
    font-size: 35px;
}

.deposit-amount-block .MuiFormControl-root.MuiTextField-root,
.deposit-amount-block .MuiFormControl-root.MuiTextField-root label {
    text-align: right !important;
}

.deposit-amount-block-approx {
    text-align: right;
}

.deposit-amount-block {
    text-align: right;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, .1);
    background-color: #eeeaf4;
    border-radius: 16px;
    padding: 10px;
    margin-top: 20px;
}

.deposit-amount-block input {
    padding: 0px;
    position: relative;
    text-align: right;
}

.deposit-amount-block .MuiFormControl-root.MuiTextField-root label {
    position: relative !important;
    right: 0 !important;
    width: 100% !important;
    max-width: 96% !important;
}

.deposit-amount-block {
    text-align: right;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, .1);
    background-color: #eeeaf4;
    border-radius: 16px;
    padding: 10px;
    margin-top: 20px;
}

.deposit-amount-block input {
    padding: 0px;
    position: relative;
    text-align: right;
}

.deposit-amount-block .MuiFormControl-root.MuiTextField-root label {
    position: relative !important;
    right: 0 !important;
    width: 100% !important;
    max-width: 96% !important;
}

.balance-receive {
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, .1);
    background-color: #eeeaf4;
    border-radius: 16px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.balance-receive-token {
    display: flex;
    align-items: center;
    gap: 10px;
}

.balance-receive-token-img {
    background: #585656;
    border-radius: 50%;
    width: 35px;
}

.balance-receive-token-name label {
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

span.value-differ-block-identidy {
    font-size: 20px;
    color: #000;
    font-weight: 700;
}

.token-deposit-amount-left svg {
    cursor: pointer;
}

div#liquid-staking-id {
    min-height: calc(100vh - 110px);
    background: #181a1a;
}

.live.liquid-staking-table {
    max-width: 700px;
    margin: 0 auto;
}

.usdt-third-td.disabled-btn-style button {
    background: #1fc7d4;
    text-transform: none;
    box-shadow: none;
}

.live.liquid-staking-table .table-contain {
    background: #27262c;
    border: 1px solid #383241;
}

.live.liquid-staking-table .table-contain td {
    border-bottom: 2px solid #524b63;
}

.live.liquid-staking-table .table-contain tbody:last-child td {
    border-bottom: none;
}

.live.liquid-staking-table .table-contain .usdt-fifty-td .doller-value {
    color: #ffffff;
    font-size: 16px;
    margin-top: 5px;
}

.swaping-page.liquid-staking-main-body {
    height: auto;
}

.sortby.first {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}


.sortby.first div#demo-simple-select {
    color: #fff;
    padding-top: 7px;
}
.liquid-staking svg {
    fill: #787E87;
}

.swaping-page.liquid-staking-main-body .table-head{
    color: #787E87;
    font-weight: 700;
    font-size: 16px;
}
/* .swaping-page.liquid-staking-main-body .MuiPaper-root.MuiPaper-elevation{
    background: #000 !important;
} */
.swaping-page.liquid-staking-main-body  input.MuiInputBase-input::placeholder {
    color: #ffff;
}
.swaping-page.liquid-staking-main-body  input.MuiInputBase-input{
    color: #fff;
}
.modal-box-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: #373C3E;
    padding: 20px;
    border-radius: 10px;
    /* overflow-y: scroll;
    height: 70vh; */
}
 .accordion-tab-head.staking-table{
    background: #373C3E!important;
    box-shadow: none !important;
}
.swaping-page.liquid-staking-main-body .titlename h4 {
    color: #fff;
}
.usdt-fifty-td h4 {
    color: #fff;
    white-space: nowrap;
    margin: 0;
}
.accordion-tab-head td.MuiTableCell-root{
    border-bottom: none !important;
}
.live.liquid-staking-table button {
    background: #1fc7d4;
    color: #fff;
}
.usdt-fifty-td .doller-value button{
    white-space: nowrap;
    padding: 0;
}
.close-menu svg {
    fill: #fff;
    background: gray;
    border-radius: 50px;
    padding: 3px;
}

.close-menu {
    text-align: end;
}
.modal-box-popup .usdt-fifty-td .doller-value {
    color: #bcbcbc;
}
.modal-box-popup .MuiTableContainer-root {
    background: none !important;
    box-shadow: none !important;
}

div#liquid-staking-id .liquid-stake {
    border-radius: 24px;
}

.dals-back {
    text-align: left;
    padding: 15px 10px 0;
}

.dals-back svg {
    background: #53d6ff;
    color: #fff !important;
    fill: #fff;
    border-radius: 50%;
}

.liquid-staking-classname > .MuiPaper-root, .liquid-staking-classname > .MuiPaper-root .liquid-staking {
    height: 100%;
}

.liquid-staking-classname > .MuiPaper-root .liquid-staking {
    display: flex;
    align-items: center;
}

div#deposit-amount-liquid-staking {
    width: 100%;
    height: auto !important;
}

@media (max-width:991.98px) {

    .live.liquid-staking-table tbody.accordion-tab-head {
        flex-wrap: nowrap !important;
        overflow: auto !important;
    }

    .live.liquid-staking-table tbody.accordion-tab-head td:nth-last-child(2),
    .live.liquid-staking-table tbody.accordion-tab-head td:nth-last-child(3),
    .live.liquid-staking-table tbody.accordion-tab-head td:nth-last-child(4),
    .live.liquid-staking-table tbody.accordion-tab-head td:nth-last-child(5) {
        display: block !important
    }

    .modal-box-popup{
        width: 65%;
    }
    .modal-box-popup tbody.accordion-tab-head td:nth-last-child(2),.modal-box-popup tbody.accordion-tab-head td:nth-last-child(3), .modal-box-popup tbody.accordion-tab-head td:nth-last-child(4),.modal-box-popup tbody.accordion-tab-head td:nth-last-child(5){
        display: block !important;
        width:auto;
    }
    .modal-box-popup tbody.accordion-tab-head{
        flex-wrap: nowrap !important;
    }
}

.titlename {
    display: flex;
    gap: 20px;
    align-items: center;
}

.swaping-page.liquid-staking-main-body .liquid-staking {
    max-width: 1051px;
    margin: 0 auto;
}