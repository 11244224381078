.MuiTabs-flexContainer button {
    min-width: auto;
    text-transform: none;
}

.MuiTabs-flexContainer button {
    color: rgba(255, 255, 255, 0.35);
}


.MuiTabs-flexContainer {
    padding: 5px;
}

.market-left-left {
    display: flex;
    flex-direction: column;
}

.market-left {
    display: flex;
    justify-content: space-between;
}

.market-left-left label {
    color: #fff;
}

.market-left span {
    color: rgba(255, 255, 255, 0.58);
}

.market-right-inner {
    display: flex;
    flex-direction: column;
}

.market-right-inner label {
    color: #f00;
}

.market-right-inner span {
    color: rgba(255, 255, 255, 0.58);
}

.tabcoinsleftcontain .Mui-selected.buy-tab {
    color: #fff !important;
    background: transparent;
    position: relative;
    border: none !important;
    background: #23d886 !important;
    background: #46FF6F !important;
    border-radius: 6px;
    font-size: 14px !important;
}

.tabcoinsleftcontain .Mui-selected.sell-tab {
    color: #fff !important;
    background: transparent;
    position: relative;
    border: none !important;
    background: #dc3320 !important;
    border-radius: 6px;
    font-size: 14px !important;
}

.tabcoinsleftcontain .buy-tab, .tabcoinsleftcontain .sell-tab{font-size: 14px !important;}

/* .tabcoinsleftcontain .Mui-selected::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #23d886 !important;
    bottom: 0px;
} */


.clear-order-form svg {
    height: 20px;
    color: rgba(255, 255, 255, 0.25);
}

.clear-order-form {
    text-align: right;
    margin-top: 0px;
    margin-right: 10px !important;
}

.clear-order-form a {
    margin-top: 5px;
    display: inline-block;
}

.incre-dec-box {
    margin: 10px;
    position: relative;
    margin-top: 20px;
}

.incre-dec-box input {
    border: 1px solid rgba(187, 205, 240, 0.15);
    box-shadow: 0 0 0 1px rgb(0 0 0 / 80%);
    z-index: 1;
    border-radius: 2px;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: right;
    padding-right: 30px;
}

.incre-dec {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 50px;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 99;
}

.incre-dec svg {
    font-size: 12px;
}

.incre-dec button {
    min-width: auto !important;
    background: rgba(187, 205, 240, 0.06);
    color: #fff;
    padding: 5px 0px;
    width: 25px;
}

form.incre-dec-box.pricebox .MuiInputBase-formControl {
    position: relative;
}

form.incre-dec-box.pricebox .MuiInputBase-formControl::before {
    content: 'Price';
    position: absolute;
    color: rgba(255, 255, 255, 0.78);
    left: 15px;
    font-size: 12px;
}

form.incre-dec-box.quantity .MuiInputBase-formControl::before {
    content: 'Quantity';
    position: absolute;
    color: rgba(255, 255, 255, 0.78);
    left: 15px;
    font-size: 12px;
}

form.incre-dec-box.plusfree .MuiInputBase-formControl::before {
    content: 'Total plus free';
    position: absolute;
    color: rgba(255, 255, 255, 0.78);
    left: 15px;
    font-size: 12px;
}

.buttons-top {
    display: flex;
    justify-content: flex-end;
}

.buttons-top button {
    border-radius: 2px;
    background-color: rgba(187, 205, 240, 0.06);
    height: 20px;
    font-size: 11px;
    padding: 1px 5px;
    color: rgba(255, 255, 255, 0.58);
    /* display: inline-block !important; */
    min-width: auto;
    margin-left: 2px;
    text-transform: none;
}

form.incre-dec-box.quantity {
    margin-top: 30px;
}

form.incre-dec-box.plusfree {
    margin-top: 12px;
}

.confirmation {
    display: flex;
    padding: 6px 14px 6px 20px;
    justify-content: space-between;
    border-top: 1px solid #000;
    margin-top: 30px;
}

form.confirmation label {
    margin: 0px;
}

.confirmation-left span {
    padding-left: 0px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
}

.confirmation-right span {
    padding-right: 0px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
}

.market-trade-availability {
    flex: 0 0 auto;
    line-height: 1;
    max-width: 100%;
    font-size: 10px;
    padding: 4px 10px !important;
    text-align: center;
    border-top: 1px solid #070b10;
    color: rgba(255, 255, 255, 0.35);
    background-color: rgba(24, 34, 47, 0.75);
}

.order-button-container.floating {
    bottom: 18px;
    padding: 10px;
    position: absolute;
    background: rgba(24, 34, 47, 0.9);
    box-shadow: 0 -1px 0 0 #050b13;
    z-index: 2;
    width:95%;
}

.logged-out-buttons {
    font-size: 12px;
}

.logged-out-buttons  p {
    line-height: 1.3;
    margin: 0.8rem 0;
    padding: 0;
    color: #fff;
    text-align: left;
}

.logged-out-buttons .logged-out-buttons--container {
    display: flex;
    justify-content: space-between;
}

.logged-out-buttons .logged-out-buttons--container .button {
    text-align: center;
    width: calc(50% - 4px);
    justify-content: center;
    color: #ffffff !important;
    background-color: #0081eb;
    cursor: pointer;
    font-weight: 500;
    padding: 8px 10px;
    text-decoration: none;
    width: 40%;
}

.scroll-part-top {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: visible;
    position: relative;
    max-height: 385px;
}

.order-button-container-fake-space.loggedOutButtons {
    flex: 0 0 102px;

}

.order-component {
    display: block;
    overflow: auto;
    position: relative;
}

.order-component::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .order-component::-webkit-scrollbar-track {
    background: #131722;
  }
  
  /* Handle */
  .order-component::-webkit-scrollbar-thumb {
    background: rgb(56, 55, 55);
    border-radius: 2px;
  }
  
  /* Handle on hover */
  .order-component::-webkit-scrollbar-thumb:hover {
    background: rgb(85, 85, 85);
  }

  /* .dots-block svg {
    font-size: 14px;
}

.dots-block {
    margin-right: 10px;
} */


h4.sell-buy-trade-head {
    margin: 0px;
    font-size: 15px;
    font-size: 15px;
    color: #fff;
    text-align: left;
    margin-bottom: 15px;
}

.sell-buy-trade {
    padding: 15px;
    background: #373C3E;
    border-bottom: 1px solid #292A2A;
}

div#buy-sell-tab {
    background: #181A1A;
    border-radius: 9px;
    padding: 3px;
}

.price-buy-input {
    display: flex;
    justify-content: space-between;
    background: #181A1A;
    padding: 10px !important;
    border-radius: 9px;
}

.price-buy-input span {
    font-size: 14px;
    font-weight: 700;
    color: #787E87;
}

.price-buy-input input {
    background: transparent;
    border: none;
    outline: none;
    color:#fff;
    text-align: center;
}

div#buy-sell-tab {
    margin-bottom: 7px;
}

.price-buy-input {
    margin: 15px 0;
    /* display: inline-block; */
    /* width: 100%; */
}


#myRange {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: #292A2B;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 10px;
  }
  
  #myRange:hover {
    opacity: 1;
  }
  
  #myRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: #fff;
    cursor: pointer;
    border-radius:50%
  }
  
  #myRange::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #fff;
    cursor: pointer;
  }

  button.Buy-BTC-button {
    background: #46FF6F;
    border-radius: 9px;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    color: #283340;
    font-weight: 700;
}

button.Buy-BTC-button:hover {
    background: #00c22b;
    color: #fff;
}

div#buy-sell-all-tab button {
    font-size: 12px;
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 6px;
}

div#buy-sell-all-tab button.Mui-selected.buy-tab {
    background: #292A2B;
    border-radius: 6px;
    color: #46ff6f !important;
    padding: 5px 10px;
}

div#buy-sell-all-tab button.Mui-selected.sell-tab {
    color: #FF8383 !important;
    background: #292A2B;
    border-radius: 6px;
    padding: 5px 10px;
}

.my-order-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

h4.sell-buy-trade-head.my-order-head {
    margin-bottom: 0px;
}

.my-order-tab-head .MuiTabs-flexContainer {
    display: flex;
    margin: 0px;
    height: 100%;
    align-items: center;
}

.my-order-list {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
}

.order-rise {
    font-weight: 700;
}

.order-timing {
    color: #787E87;
}

.order-rise {
    color: #46FF6F;
}

.order-qty {
    color: #fff;
}

.delete-order svg {
    background: #fff;
    border-radius: 50%;
    font-size: 15px;
}

div#buy-sell-all-tab button.Mui-selected.sell-tab {
    color: #FF8383 !important;
}

button.Buy-BTC-button.sell-btn {
    background: #dc3320;
    color: #fff;
}

button.Buy-BTC-button.sell-btn:hover {
    background: #FF8383 !important;
}

.sell-buy-trade.my-order-items.Trade-History-outer {
    background: #18222f;
}

