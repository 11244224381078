.left-make-proposal strong, .right-make-proposal strong {
    color: #fff;
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 20px;
    text-align: left !important;
}

.Title input, .right-make-proposal input {
    background-color: #18222f;
    border-radius: 16px;
    box-shadow: inset 0px 2px 2px -1px rgba(74,74,104,.1);
    color: #fff;
    display: block;
    font-size: 16px;
    height: 48px;
    outline: 0px;
    padding: 0px 16px;
    width: 97%;
    border: 1px solid #d7caec;
}

.Title, .Content {
    margin-bottom: 30px;
}

.Content p {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-top: 0px;
}

.right-make-proposal label {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #280d5f;
    display: block;
    text-align: left;
}

.date-block-style fieldset {
    border: none !important;
}

.date-block-style .MuiFormControl-root {
    width: 100%;
}

.right-make-proposal .date {
    margin-top: 20px;
}

button.publish-btn {
    width: 100%;
    color: #000;
    height: 48px;
    font-size: 20px;
    font-weight: 700;
    text-transform: none;
    margin-top: 20px;
}

.cretor-snapshot-block {
    display: flex;
    font-weight: 400;
    line-height: 1.5;
    /* margin-right: 16px; */
    font-size: 16px;
    margin: 5px 0;
}

.cretor-snapshot-block label {
    margin-right: 16px;
    font-size: 16px;
}

.cretor-snapshot-block span {
    font-weight: 700;
}

p.note-note {
    color: #ed4b9e;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 4px;
    font-size: 18px;
    text-align: left;
}

.Choices strong {
    margin-bottom: 0px;
    /* background: linear-gradient(111.68deg,#f2ecf2,#e8f2f6); */
    background: #282e36;
    border-radius: 24px 24px 0px 0px;
    padding: 24px;
}

.Choices-inner {
    padding: 19px 24px;
}

.Choices-inner input {
    background-color: transparent;
    border-radius: 16px;
    box-shadow: inset 0px 2px 2px -1px rgba(74,74,104,.1);
    color: #fff;
    display: block;
    font-size: 16px;
    height: 48px;
    outline: 0px;
    padding: 0px 16px;
    width: 100%;
    border: 1px solid #d7caec;
    width: 100%;
}

.Choices-inner .MuiFormControl-root {
    width: 100%;
    border: none;
    background: transparent !important;
}

.Choices-inner .MuiInputBase-root::before, .Choices-inner .MuiInputBase-root::after {
    border: none !important;
    background: transparent !important;
}

.Choices-inner .MuiInputBase-root {
    background-color: transparent !important;
}

.Choices {
    border: 1px solid #d7caec;
    border-radius: 24px;
}

.add-choices-outer {
    margin-bottom: 20px;
    text-align: left;
    margin-left: 27px;
    margin-top: 20px;
}

.add-choices-outer button {
    background: #53d6ff;
    text-transform: none;
    font-size: 18px;
    box-shadow: none;
}

a.back-to-page-btn {
    display: flex;
    text-align: left;
    margin-bottom: 30px;
    font-size: 18px;
    gap: 10px;
    text-decoration: none;
    font-weight: 700;
    color: #52d5fd;
}

.date .MuiInputBase-root.MuiOutlinedInput-root {
    background: transparent;
    border: 1px solid #eeeaf4;
    border-radius: 10px;
}

.date input {
    border: none;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners p {
    color: #000;
}
.date .MuiInputBase-root.MuiOutlinedInput-root button svg path{
    fill: #fff;
}


@media (max-width:991.98px){
.right-make-proposal input{width: auto !important;}
.main-top-container-class{padding-left: 15px !important; padding-right: 15px !important;}
.community-page.make-proposal-page div#main-top-container {
    padding-left: 15px;
    padding-right: 15px;
}
.Title input{width: 90% !important;}
}
