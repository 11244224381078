.kyc-form-portion{
    background-color: #000 !important;
}

h3.sub-heading-part {
    padding: 30px 0 20px;
    color: #ccc;
    font-size: 20px;
    border-bottom: 2px dashed;
}

.kyc-form-portion .MuiInputBase-root:hover fieldset {
    border: rgb(0, 0,0, 0.23) !important;
}

.address-box input {height: 100px;}

p.color-dim {
    margin-top: 0;
    color: #ccc;
    font-weight: 600;
    font-size: 15px;
}

ul.color-dim {
    padding-left: 0px;
    list-style: none;
    margin-top: 0px;
}

ul.color-dim li {
    color: #ccc;
    line-height: 27px;
}

.datepicpart {
    display: flex;
    align-items: center;
}

.country-get-box-modal .MuiFormControl-root {
    border: 1px solid #ccc;
    border-radius: 5px;
}

.country-get-box-modal .MuiFormControl-root input {
    border: none !important;
}

.country-get-box-modal svg, .datepicpart svg {
    color: #ccc !important;
}

.datepicpart .MuiInputBase-root {
    border: 1px solid #ccc;
}

.datepicpart input {
    border: none !important;
}

.datepicpart div#demo-simple-select {
    color: #ccc !important;
}

.flex-column{
    flex-direction: column !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 60px;
}

.ID-document-number{padding-left: 60px;}

.id-type{margin-bottom: 25px !important;}

p.para-cmn {
    margin-top: 0px;
    color: #ccc;
}

.inner-doc {
    color: #fff;
    text-align: center;
    background: #363636;
    padding: 1.2rem;
    border-radius: 10px;
    margin: 20px 0 10px;
}

.inner-doc h3 {
    font-size: 18px;
}

.inner-doc button {
    background: #0963d2;
    color: #fff;
}

.inner-doc img {
    width: 100px;
}

label.upload-btn {
    background: #0963d2 !important;
    color: #fff;
    padding: 8px;
    font-size: 16px;
    margin-top: 20px !important;
    display: inline-block;
    border-radius: 4px;
}
h2.alpha-login-text {
    font-size: 30px;
    color: #fff;
    text-align: left;
}
.datepicpart div {
    width: 100%;
}



.flag-image-code img {height: 15px;}

ul.coin-list-menu.country-detailed-list {
    list-style: none;
    padding-left: 0px;
    min-height: 270px;
    max-height: 270px;
    overflow: auto;
}

.coin-img.country-img {
    display: flex;
    color: #fff;
    font-size: 12px;
    gap: 20px;
    justify-content: space-between;
    /* padding: 0 5px; */
}

.coin-img-inner.country-dtls {
    display: flex;
    align-items: center;
    gap: 5px;
}

ul.coin-list-menu.country-detailed-list li {
    padding: 10px 5px;
}

.flag-image-code h3 {
    margin: 0px !important;
}

.coin-img.country-img h3, .coin-img.country-img h4 {
    margin: 0px;
}


ul.coin-list-menu.country-detailed-list::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  ul.coin-list-menu.country-detailed-list::-webkit-scrollbar-track {
    background: #1c1b1b; 
  }
   
  /* Handle */
  ul.coin-list-menu.country-detailed-list::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ul.coin-list-menu.country-detailed-list::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }