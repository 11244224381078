.application-body {
    text-align: left;
    color: #fff;
    padding: 60px;
    border: 1px solid #373c3e;
    border-radius: 9px;
}

.application-body h3 {
    font-size: 18px !important;
    font-weight: 700;
    margin-top: 0px;
}

.application-body-list ul {
    padding: 0px;
    margin: 0px;
}

.application-body-list {
    padding: 30px 30px 30px 40px;
    background: #373c3eba ;
    border-radius: 9px;
    margin-bottom: 30px;
}

.application-body-list ul li {
    margin-bottom: 30px;
    font-size: 15px;
}

.application-body-list ul li:last-child {
    margin-bottom: 0px;
}

.application-body p {
    font-size: 15px;
}

a.Start-Application-btn {
    background: #53D6FF;
    box-shadow: none;
    text-transform: none;
    margin-top: 30px;
    text-decoration: none;
    color: #000;
    padding: 9px 9px;
    border-radius: 10px;
}
@media(max-width:767.98px){
    .application-body{
        padding: 15px !important;
    }
}