.coin-main-table-outer .MuiTabs-flexContainer button {
    color: #787E87 !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    letter-spacing: 0px !important;
}

.coin-main-table-outer .MuiTabs-flexContainer {
    margin: 0px !important;
    border: none !important;
}

.coin-main-table-outer .MuiTabs-flexContainer .Mui-selected {
    border: none !important;
    background: transparent !important;
    color: #181A1A !important;
}

.eroo {
    color: red;
}